'use client';

import { Status } from '@/utils/enums';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import * as ToastRadix from '@radix-ui/react-toast';
import clsx from 'clsx';

import * as S from './styles';

type Contrast = 'low' | 'high';
export type StatusToast = Exclude<
  Status,
  'inProgress' | 'complete' | 'refunded'
>;

const statusComponents: Record<StatusToast, React.ElementType | null> = {
  info: S.SvgIconBadgeInfo,
  success: S.SvgIconBadgeSuccess,
  error: S.SvgIconBadgeError,
  warning: S.SvgIconBadgeWarning,
  notMapped: S.SvgIconBadgeInfo,
  openned: null,
};

type ToastProps = {
  title: string;
  description: string;
  status: StatusToast;
  label?: string;
  defaultOpen?: boolean;
  contrast?: Contrast;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  openToast: boolean;
  handleCloseToast: () => void;
};

const Toast = ({
  title,
  description,
  contrast = 'low',
  position = 'bottom-right',
  status,
  openToast,
  handleCloseToast,
}: ToastProps) => {
  const containerClassName = clsx(`contrast-${contrast}`, `status-${status}`);

  const StatusComponent = statusComponents[status];

  return (
    <ToastRadix.Provider swipeDirection="right">
      <S.ToastRoot
        data-testid="toast-root"
        className={containerClassName}
        open={openToast}
        onOpenChange={handleCloseToast}>
        <div className="title-icon">
          {StatusComponent && <StatusComponent />}
          <div className="texts">
            <span>{title}</span>
            <S.Title className={containerClassName}>{description}</S.Title>
          </div>
        </div>
        <ToastRadix.Action asChild altText="closed">
          <button aria-label="Fechar notificação" data-testid="close">
            <CloseOutlinedIcon fontSize="small" />
          </button>
        </ToastRadix.Action>
      </S.ToastRoot>
      <S.ToastViewport className={`position-${position}`} />
    </ToastRadix.Provider>
  );
};

export { Toast };
